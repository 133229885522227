import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
 items:any;
 total: any;
 collorprice:any;
 cuffprice:any;
 sleeveprice:any;
 pocketprice:any;
 checkoutbtn:boolean;
 btnprice:any;
 btmcutprice:any;
 backsideprice:any;
 insidecuff:any;
 insidecollar:any;
 placketprice:any;
 logCheck = false;
 acCheck = true;

 total_gr:any;

  constructor(private authService: AuthService, public router: Router) { }

  ngOnInit() {
    let logged = JSON.parse(localStorage.getItem("logIn"));
    if(logged == undefined || logged =="" || logged == null){
      this.logCheck = false;
      this.acCheck = true;
    }
    else{
      this.logCheck = true;
      this.acCheck = false;
      }
   this.loadcart();
  }
  profilego(){

    let datainfo = JSON.parse(localStorage.getItem("datainfo"));
    console.log(datainfo);
    let asd = JSON.parse(datainfo[0]);
    var mail = asd.usermail ;
    var pass = asd.userpassword ;
    var form = document.createElement("form");
    var element1 = document.createElement("input"); 
    var element2 = document.createElement("input");  

    form.method = "POST";
    form.action = "http://localhost/dashboard/user/login";
    form.target = "_blank";   

    element1.value= mail;
    element1.name="emailid";
    element1.type="hidden";
    form.appendChild(element1);  

    element2.value=pass;
    element2.name="password";
    element2.type="hidden";
    form.appendChild(element2);

    document.body.appendChild(form);

    form.submit();

    element1.value=" ";
    element1.name="emailid";
    element1.type="hidden";
    form.appendChild(element1);  

    element2.value=" ";
    element2.name="password";
    element2.type="hidden";
    form.appendChild(element2);

    document.body.appendChild(form);
  }
  loadcart() {
    this.total = 0;
    this.total_gr = 0;
    this.collorprice  = 0 ;
    this.cuffprice  = 0 ;
    this.sleeveprice  = 0 ;
    this.pocketprice  = 0 ;
    this.btnprice = 0 ;
    this.btmcutprice  = 0 ;
    this.backsideprice  = 0 ;
    this.insidecuff = 0 ;
    this.insidecollar = 0 ;
    this.placketprice = 0 ;
    this.items = [];

    if (
      localStorage.getItem("cart") == null ||
      localStorage.getItem("cart") == "[]"
    ) {
      this.checkoutbtn=true;
    } else {
      let cart = JSON.parse(localStorage.getItem("cart"));
      this.checkoutbtn=false;
      for (var i = 0; i < cart.length; i++) {
        let item = JSON.parse(cart[i]);
        this.items.push({
          product: item
        });

        //this.total += item.totalP;
        this.total += (item.fabricprice) * item.qty ;
        this.collorprice  += (item.collorprice) * item.qty ;
        this.cuffprice  += (item.cuffprice) * item.qty ;
        this.sleeveprice  += (item.sleeveprice) * item.qty ;
        this.pocketprice  += (item.pocketprice) * item.qty ;
        this.btnprice += (item.btnprice) * item.qty ;
        this.btmcutprice  += (item.btmprice) * item.qty ;
        this.backsideprice  += (item.backsideprice) * item.qty ;
        this.insidecuff += (item.insidecuffprice) * item.qty ;
        this.insidecollar += (item.collorinsdeprice) * item.qty ;
        this.placketprice += (item.placketprice) * item.qty ;
        this.total_gr = this.total + this.collorprice + this.cuffprice + this.sleeveprice + this.pocketprice + this.btnprice  + this.btmcutprice + this.backsideprice +this.insidecuff + this.insidecollar + this.placketprice;
      }
      
    }
  }

  qtyupdate(i, item){
    console.log("index:", i);
    console.log("item:", item);
    let cart = JSON.parse(localStorage.getItem("cart"));
      cart.splice(i, 1);
      //localStorage.setItem("cart", JSON.stringify(cart));
      cart.push(JSON.stringify(item));
      localStorage.setItem("cart", JSON.stringify(cart));
      this.ngOnInit();
    
  }
  removesingle(i){
    let cart = JSON.parse(localStorage.getItem("cart"));
      cart.splice(i, 1);
      //localStorage.setItem("cart", JSON.stringify(cart));
      
      localStorage.setItem("cart", JSON.stringify(cart));
      this.ngOnInit();
  }
  emptycart(){
    localStorage.clear();
    this.ngOnInit();
  }
  checkout(){
    this.router.navigate(["/checkout"]);
  }
  returnMain(){
    this.router.navigate(["/home"]);
  }
}
