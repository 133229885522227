import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";


let apiUrl = "https://design.shapesbespoke.com/API/";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  user_id : any;
  token: any;
  constructor(public http: HttpClient) {}
  postData(credentials, type) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();

      this.http
        .post(apiUrl + type, JSON.stringify(credentials), { headers: headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  loginCheck(){
    
    
  }
}
