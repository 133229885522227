import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';


const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },{
    path: "cart",
    component: CartComponent
  },{
    path: "home",
    component: HomeComponent
  },{
    path: "home/:id",
    component: HomeComponent
  },{
    path: "checkout",
    component: CheckoutComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
