import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  modalRef: BsModalRef| null;
  modalRef2: BsModalRef;
  hidefront: boolean = false;
  hideback: boolean = true;
  hidecuff: boolean = true;
  hidebottom:boolean= true;
  hideFabric: boolean = false;
  hideStyle: boolean = true;
  hideExtra: boolean = true;
  hideSize: boolean = true;
  selectedoptions:boolean=true;
  hidefullcuff:boolean = false;
  hideSummery: boolean = true;
  hideInsidecollar: boolean = true;
  hideInsidecuff: boolean = true;
  shirtplacketBtn: boolean = true;
  hidebtn: boolean = true;
  hidepocket: boolean = true;
  hidesizes: boolean = false;
  stdhide: boolean = true;
  hidestandard: boolean = true;
  
  // size images
  neck_body: boolean = true;
  chest_body: boolean = true;
  waist_body: boolean = true;
  hips_body: boolean = true;
  shirtlength_body: boolean = true;
  shoulder_body: boolean = true;
  armlength_body: boolean = true;
  armhole_body: boolean = true;
  bicep_body: boolean = true;
  wrist_body: boolean = true;
  armshort_body: boolean = true;
  cuffshort_body: boolean = true;
  neck_shirt: boolean = true;
  chest_shirt: boolean = true;
  waist_shirt: boolean = true;
  hips_shirt: boolean = true;
  shirtlength_shirt: boolean = true;
  shoulder_shirt: boolean = true;
  armlength_shirt: boolean = true;
  armhole_shirt: boolean = true;
  bicep_shirt: boolean = true;
  cuff_shirt: boolean = true;
  armshort_shirt: boolean = true;
  cuffshort_shirt: boolean = true;
  shouldertypes_body: boolean = false;
  // standart values
  stheight:any;
  stcollorsize:any;
  stfit:any;
  stweight:any;
  stshouldertype:any;
  stshoulder:any;
  stsleeve:any;
  stchest:any;
  stwaist:any;
  sthip:any;
  stlength:any;
  stsleevew:any;
  stcuffw:any;
  
  // body size values
  bodyfit:any;
  bodyheight:any;
  bodyweight:any;
  bodyshouldertype:any;
  bodyneck:any;
  bodychest:any;
  bodywaist:any;
  bodyhip:any;
  bodyshirtlength:any;
  bodyshoulder:any;
  bodyarm:any;
  bodyarmhole:any;
  bodybicep:any;
  bodywrist:any;
  bodyarmlength:any;
  bodycuffshort:any;

  //shirt size value

  shirtshouldertype:any;
  shirtheight:any;
  shirtweight:any;
  shirtneck:any;
  shirtchest:any;
  shirtwaist:any;
  shirthips:any;
  shirtlength:any;
  shirtshoulder:any;
  shirtarmlength:any;
  shirtarmhole:any;
  shirtbicep:any;
  shirtcuffsize:any;
  shirtarmlengthshort:any;
  shirtshortcuff:any;



  sleevetype:string='full';
  fitType = {
    sl: "",
    ft: ""
  }
  loading = false;
  responseData: any;
  items: any;
  parts:any;
  Extraparts:any;
  responseDataparts:any;
  fabimg:any;
  userPostData={
    fabricid: ""
  };
  imgURL: any;
  fabricinfo={
    fabric_name:"",
    fabric_desc:"",
    fabric_price:"",
    fabric_tumbnail:""
  }
//img var
  shirtcollar: any;
  shirtcuff: any;
  shirtbase: any;
  shirtplacket: any;
  shirtplacketbtn:any;
  shirtpocket: any;
  shirtback: any;
  shirtbottom: any;
  shirtInsideCollar:any;
  shirtInsideCuff:any;
  shirtbtn: any;
  shirtarms: any;
  shirtpocketflap:any;
  changedoption:any;
  responseShirtData:any;
  responseStandardsize:any;
  responseBodysize:any;
  responseShirtsize:any;
  fabricName:any;
  shirts:any;
  shirtData = {
    fabricid:"",
    fabricname:"",
    fabricprice:"",
    extraprice:"",
    baseid: "",
    sleeveid: "",
    sleevename:"",
    sleevetype:"",
    sleeveprice:"",
    collarid: "",
    collarname:"",
    collorprice:"",
    cuffid: "",
    cuffname:"",
    cuffprice:"",
    placketid: "",
    placketname:"",
    placketprice:"",
    pocketid: "",
    pocketname:"",
    pocketprice:"",
    backsideid:  "",
    backsidename:"",
    backsideprice:"",
    btmcutid: "",
    btmcutname:"",
    btmprice:"",
    btnid: "",
    btnname:"",
    btnprice:"",
    btnthreadid:"",
    btnthreadhole:"",
    collarinsideId:"",
    collorinsidename:"", 
    collorinsdeprice:"",
    insidecuffid:"",
    insidecuffname:"",
    insidecuffprice:"",
    btnplacket:"",
    btnplacketname:"",
    btnplacketprice:"",
    sizetype:"",
    qty:"1",
      
    typeoffitStandard:"",
    colorsizes:"",
    heightStandard:"",
    weightStandard:"",
    shouderStandard:"",
    sleeveStandard:"",
    chestStandard:"",
    waistStandard:"",
    hipsStandard:"",
    lenghtStandard:"",
    sleevewidthStandard:"",
    cuffwidthStandard:"",
  
    typeoffitbody:"",
    heightbody:"",
    weightbody:"",
    shouldertypebody:"",
    neckbodys:"",
    chestbodys:"",
    waistbodys:"",
    hipsbodys:"",
    shirtlenghtbodys:"",
    shoulderbodys:"",
    armlenghtbodys:"",
    armholebodys:"",
    bicepbodys:"",
    wristbodys:"",
    armlengtshorthbodys:"",
    shortcuffbodys:"",
  
    shirtshouldertype:"",
    heightshirt:"",
    weightshirt:"",
    neckshirt:"",
    chestshirt:"",
    waistshirt:"",
    hipsshirt:"",
    shirtlenghtshirt:"",
    shouldershirt:"",
    armlenghtshirt:"",
    armholeshirt:"",
    bicepshirt:"",
    cuffshirt:"",
    armlengtshortshirt:"",
    shortcuffshirt:""
    
    
    
  }
  cShirt = {
    fabricID: "",
    sleeveID: "",
    cuffID: "",
    cuffIDoption: "",
    baseID: "",
    collorID: "",
    collorIDoption: "",
    placketID: "",
    placketIDoption: "",
    buttonID: "",
    backID: "",
    shirttype:""

  }
  responseDataFabric:any;
  fabData:any;
  //Standard Sizes
  standardsizes:any;
  typeoffitStandard:any;
  colorsizes:any;
  heightStandard:any;
  weightStandard:any;
  shouderStandard:any;
  sleeveStandard:any;
  chestStandard:any;
  waistStandard:any;
  hipsStandard:any;
  lenghtStandard:any;
  sleevewidthStandard:any; 
  cuffwidthStandard:any;

  standardsizes1:any;
  typeoffitStandard1:any;
  colorsizes1:any;
  heightStandard1:any;
  weightStandard1:any;
  shouderStandard1:any;
  sleeveStandard1:any;
  chestStandard1:any;
  waistStandard1:any;
  hipsStandard1:any;
  lenghtStandard1:any;
  sleevewidthStandard1:any; 
  cuffwidthStandard1:any;
  
  stdc:any;
  shouldercount:any;
  sleevecount:any;
  chestcount:any;
  wasitcount:any;
  hipcount:any;
  lengthcount:any;
  sleevewidthcount:any;
  cuffwidth:any;

  //body sizes
  bodysizes:any;
  typeoffitbody:any;
  heightbody:any;
  weightbody:any;
  shouderbody:any;
  neckbody:any;
  chestbody:any;
  waistbody:any;
  hipsbody:any;
  shirtlenghtbody:any;
  armlenghtbody:any;
  armholebody:any;
  bicepbody:any;
  wristbody:any;
  armlengtshorthbody:any;
  shortcuffbody:any;
  //Shirt sizes
  shirtsizes:any;
  heightshirt:any;
  weightshirt:any;
  neckshirt:any;
  chestshirt:any;
  waistshirt:any;
  hipsshirt:any;
  shirtlenghtshirt:any;
  shouldershirt:any;
  armlenghtshirt:any;
  armholeshirt:any;
  bicepshirt:any;
  cuffshirt:any;
  armlengtshortshirt:any;
  shortcuffshirt:any;
  // style content
  userPostStyle={
    partid:"",
    pocketstyleid:"",
    pocketplaceid:"",
    pocketplaceflap:"",
    fabricID: ""
  };
  partName:string;
  ExtraPartName :string;
  responseShirtstyle:any;
  shirtstyles:any;
  ExtraShirtstyles:any;
  // Change style content
  userPostStyleChange={
    partsid:"",
    pocketstyleid:"",
    pocketplaceid:"",
    flapsval:"",
    fabid:"",
    changedfab:"",
    changedoption:"",
    
    optionid:""
  };
  responseShirtstyleChange:any;
  shirtstyleChange:any;
  cartlength:0;
  logCheck = false;
  acCheck = true;
  constructor(private authService: AuthService, public route:ActivatedRoute , public router: Router ,private modalService: BsModalService) { 
    this.imgURL = "assets/";
  }

  ngOnInit() {
    
    this.getitems();
    this.getParts();
    this.getRestParts();
    this.loadFirst();
    this.stndsize();
    let cart = JSON.parse(localStorage.getItem("cart"));
    this.cartlength = cart.length;
    //console.log(this.authService.token);
    
    // if(this.authService.token == undefined || this.authService.token =="" || this.authService.token == null){
    //   this.logCheck = false;
    //   this.acCheck = true;
    // } else{
    // this.logCheck = true;
    // this.acCheck = false;
    // }
    let logged = JSON.parse(localStorage.getItem("logIn"));
    if(logged == undefined || logged =="" || logged == null){
      this.logCheck = false;
      this.acCheck = true;
    }
    else{
      console.log(logged);
      this.logCheck = true;
      this.acCheck = false;
      }
    
  }
  profilego(){

    let datainfo = JSON.parse(localStorage.getItem("datainfo"));
    console.log(datainfo);
    let asd = JSON.parse(datainfo[0]);
    var mail = asd.usermail ;
    var pass = asd.userpassword ;
    var form = document.createElement("form");
    var element1 = document.createElement("input"); 
    var element2 = document.createElement("input");  

    form.method = "POST";
    form.action = "http://localhost/dashboard/user/login";
    form.target = "_blank";   

    element1.value= mail;
    element1.name="emailid";
    element1.type="hidden";
    form.appendChild(element1);  

    element2.value=pass;
    element2.name="password";
    element2.type="hidden";
    form.appendChild(element2);

    document.body.appendChild(form);

    form.submit();

    element1.value=" ";
    element1.name="emailid";
    element1.type="hidden";
    form.appendChild(element1);  

    element2.value=" ";
    element2.name="password";
    element2.type="hidden";
    form.appendChild(element2);

    document.body.appendChild(form);
  }
  loadFirst(){
    let id = this.route.snapshot.paramMap.get('id');
    if (id != null) {
      this.externalurl(id);
    } else {
      
    
    this.loading = true;
    this.authService.postData(this.userPostData, "shirtData").then(
      result => {
        this.responseShirtData = result;
        if (this.responseShirtData.Data) {
          this.loading = false;
          this.shirts = this.responseShirtData.Data;
          
          this.shirtcollar = this.shirts[2].image;
          this.shirtcuff = this.shirts[3].image;
          this.shirtbase = this.shirts[0].image;
          this.shirtplacket = this.shirts[4].image;
          this.shirtarms = this.shirts[1].image;
          this.cShirt.fabricID = this.shirts[2].fabric_id;
          this.fabricName = this.shirts[2].fabric_name;
          this.cShirt.cuffID = this.shirts[3].part_id;
          this.cShirt.cuffIDoption = this.shirts[3].option_id;
          this.cShirt.collorID = this.shirts[2].part_id;
          this.cShirt.collorIDoption = this.shirts[2].option_id;
          this.cShirt.placketID = this.shirts[4].part_id;
          this.cShirt.placketIDoption = this.shirts[4].option_id;
          this.cShirt.sleeveID = this.shirts[1].part_id;
          this.cShirt.baseID = this.shirts[0].part_id;
          
          this.shirtData.cuffid = this.shirts[3].styleid;
          this.shirtData.collarid = this.shirts[2].styleid;
          this.shirtData.placketid = this.shirts[4].styleid;
          this.shirtData.sleeveid = this.shirts[1].styleid;
          this.shirtData.baseid = this.shirts[0].styleid;
          this.sleevetype = 'full';
          this.shirtData.cuffname = this.shirts[3].style_name;
          this.shirtData.collarname = this.shirts[2].style_name;
          this.shirtData.placketname = this.shirts[4].style_name;
          this.shirtData.fabricname = this.shirts[0].fabric_name;
          this.shirtData.fabricprice = this.shirts[0].fabric_price;

          this.shirtData.fabricid = this.shirts[0].fabric_id;
          
          
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
    }
  }
  // style navigations
  
  loadStyle(part){
    this.userPostStyle.partid=part.id;
    this.userPostStyle.fabricID = this.cShirt.fabricID;
    this.partName = part.part_name ;
    this.loading = true;
    console.log(this.userPostStyle.partid);
    this.authService.postData(this.userPostStyle, "shirtStyles").then(
      result => {
        this.responseShirtstyle = result;
        if (this.responseShirtstyle.Data) {
          this.loading = false;
          this.shirtstyles = this.responseShirtstyle.Data;
          
          
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
  }
  loadExtraStyle(Extrapart ){
    this.userPostStyle.partid=Extrapart.id;
    this.userPostStyle.fabricID = this.cShirt.fabricID;
    this.ExtraPartName = Extrapart.part_name ;
    this.loading = true;
    console.log(this.userPostStyle.partid);
    this.authService.postData(this.userPostStyle, "ExtraShirtstyles").then(
      result => {
        this.responseShirtstyle = result;
        if (this.responseShirtstyle.Data) {
          this.loading = false;
          this.ExtraShirtstyles = this.responseShirtstyle.Data;
          
          
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
  }
  
  changeExtraStyle(ExtraShirtstyle){
    this.userPostStyleChange.partsid=this.userPostStyle.partid;
    if (this.userPostStyleChange.partsid == '13') {
      this.changedoption = this.cShirt.collorIDoption;
    } 
    else if(this.userPostStyleChange.partsid == '16') {
      this.changedoption = this.cShirt.cuffIDoption;
      
    }
    else if(this.userPostStyleChange.partsid == '17') {
      this.changedoption = this.cShirt.cuffIDoption;
      
    }
    else if(this.userPostStyleChange.partsid == '19') {
      this.changedoption = this.cShirt.placketIDoption;
      
    }
    if (this.userPostStyleChange.partsid  == '23' ) {
      
      this.userPostStyleChange.pocketstyleid=this.userPostStyle.pocketstyleid;
    }
    
    if (this.userPostStyleChange.partsid != '13') {
      this.userPostStyleChange.optionid=ExtraShirtstyle.id;
      
      
    }
    if (ExtraShirtstyle.id == '14' || ExtraShirtstyle.id == '15') {
      this.userPostStyle.pocketplaceid = ExtraShirtstyle.id;
      
      this.userPostStyleChange.pocketplaceid=this.userPostStyle.pocketplaceid;
    }
    if (ExtraShirtstyle.id == '16' || ExtraShirtstyle.id == '17'|| ExtraShirtstyle.id == '18') {
      this.userPostStyle.pocketstyleid = ExtraShirtstyle.id
      this.userPostStyleChange.pocketstyleid=this.userPostStyle.pocketstyleid;
    }
    this.userPostStyleChange.fabid=this.cShirt.fabricID;
    this.userPostStyleChange.flapsval=this.shirtpocketflap;
    this.userPostStyleChange.changedfab=ExtraShirtstyle.id;
    this.userPostStyleChange.changedoption=this.changedoption;
    
    this.loading = true;
    this.authService.postData(this.userPostStyleChange, "changeStyle").then(
      result => {
        this.responseShirtstyleChange = result;
        if (this.responseShirtstyleChange.Data) {
          this.loading = false;
          this.shirtstyleChange = this.responseShirtstyleChange.Data;
         
          
          if(this.userPostStyle.partid == "10") {
            this.shirtbtn = this.shirtstyleChange.image;
            this.shirtData.btnid = this.shirtstyleChange.id;
            this.shirtData.btnname = this.shirtstyleChange.style_name;
            
            this.shirtData.btnprice = this.shirtstyleChange.style_price;

            this.hidefront = false;
           this.hideback = true;
            this.hidebtn = false;
            
          }
          else if(this.userPostStyle.partid == "13") {
            this.shirtcollar = this.shirtstyleChange.image;
            this.shirtData.collarid = this.shirtstyleChange.id;
            this.shirtData.collarname = this.shirtstyleChange.style_name;
            this.cShirt.collorIDoption = this.shirtstyleChange.option_id;
            
            this.shirtData.collorprice = this.shirtstyleChange.style_price;

            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "14") {
            this.shirtInsideCollar = this.shirtstyleChange.image;
            this.shirtData.collarinsideId = this.shirtstyleChange.id;
            this.shirtData.collorinsidename = this.shirtstyleChange.style_name;
            this.cShirt.cuffIDoption = this.shirtstyleChange.option_id;
            
            this.shirtData.collorinsdeprice = this.shirtstyleChange.style_price;

            this.hideInsidecollar = false;
            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "16") {
            this.shirtcuff = this.shirtstyleChange.image;
            
            this.shirtData.cuffname = this.shirtstyleChange.style_name;
            this.shirtData.cuffid = this.shirtstyleChange.id;
            
            this.shirtData.cuffprice = this.shirtstyleChange.style_price;

            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "17") {
            this.shirtInsideCuff = this.shirtstyleChange.image;
            this.shirtData.insidecuffid = this.shirtstyleChange.id;
            
            this.shirtData.insidecuffname = this.shirtstyleChange.style_name;
            
            this.shirtData.insidecuffprice = this.shirtstyleChange.style_price;

            this.hideInsidecuff = false;
            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "19") {
            this.shirtplacket = this.shirtstyleChange.image;
            this.shirtData.placketid = this.shirtstyleChange.id;
            this.shirtData.placketname = this.shirtstyleChange.style_name;
            this.cShirt.placketIDoption = this.shirtstyleChange.option_id;
            
            this.shirtData.placketprice = this.shirtstyleChange.style_price;

            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "20") {
            this.shirtplacketbtn = this.shirtstyleChange.image;
            this.shirtData.btnplacket = this.shirtstyleChange.id;
            this.shirtData.btnplacketname = this.shirtstyleChange.style_name;
            
            this.shirtData.placketprice = this.shirtstyleChange.style_price;

            this.shirtplacketBtn = false;
            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "23") {
            this.shirtpocket = this.shirtstyleChange.image;
            this.shirtData.pocketid = this.shirtstyleChange.id;
            this.shirtData.pocketname = this.shirtstyleChange.pocket_name;
            
            this.shirtData.pocketprice = this.shirtstyleChange.pocket_price;

            this.hidefront = false;
           this.hideback = true;
          }
          else {
            console.log(this.shirtstyleChange);
          }
          
          
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
  }
  
  changeStyle(shirtstyle){
    this.userPostStyleChange.partsid=this.userPostStyle.partid;
    
    
    if (shirtstyle.id == '14' || shirtstyle.id == '15') {
      this.userPostStyle.pocketplaceid = shirtstyle.id;
      
      this.userPostStyleChange.pocketplaceid=this.userPostStyle.pocketplaceid;
      let nopocket = 0;
    } 
    if (shirtstyle.id == '16' || shirtstyle.id == '17'|| shirtstyle.id == '18') {
      this.userPostStyle.pocketstyleid = shirtstyle.id;
      this.userPostStyleChange.pocketstyleid=this.userPostStyle.pocketstyleid;
    }
    
      this.userPostStyleChange.optionid=shirtstyle.id;
      this.changedoption = shirtstyle.id;
    this.userPostStyleChange.fabid=this.cShirt.fabricID;
    this.userPostStyleChange.changedfab=shirtstyle.id;
    this.userPostStyleChange.changedoption=this.changedoption;
    
    this.loading = true;
    this.authService.postData(this.userPostStyleChange, "changeStyle").then(
      result => {
        
        this.responseShirtstyleChange = result;
        if (this.responseShirtstyleChange.Data) {
          this.loading = false;
          this.shirtstyleChange = this.responseShirtstyleChange.Data;
          
          if (this.userPostStyle.partid == "1") {
            this.shirtarms = this.shirtstyleChange.image;
            this.shirtData.sleeveid = this.shirtstyleChange.id;
            this.shirtData.sleevename = this.shirtstyleChange.style_name;
            if (this.shirtstyleChange.option_id == '1') {
              this.hidecuff = true;
              this.hidefullcuff = false;
              this.sleevetype = 'full';
            }else if (this.shirtstyleChange.option_id == "2") {
              this.hidecuff = false;
              this.hidefullcuff = false;
              this.sleevetype = 'full';
            }else{
              this.hidefullcuff = true;
              this.sleevetype = 'half';
            }
            this.shirtData.extraprice =  this.shirtData.extraprice + parseInt(this.shirtstyleChange.style_price);
            this.shirtData.sleevetype = this.sleevetype;
            
            this.shirtData.sleeveprice = this.shirtstyleChange.style_price;

            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "2" ) {
            this.shirtcollar = this.shirtstyleChange.image;
            this.shirtData.collarid = this.shirtstyleChange.id;
            this.cShirt.collorIDoption = this.shirtstyleChange.option_id;
            this.shirtData.collarname = this.shirtstyleChange.style_name;
            
            this.shirtData.collorprice = this.shirtstyleChange.style_price;

            this.hidefront = false;
           this.hideback = true;
          } 
          else if(this.userPostStyle.partid == "3") {
            this.shirtcuff = this.shirtstyleChange.image;
            this.shirtData.cuffid = this.shirtstyleChange.id;
            this.cShirt.cuffIDoption = this.shirtstyleChange.option_id;
            this.shirtData.cuffname = this.shirtstyleChange.style_name;
            
            this.shirtData.cuffprice = this.shirtstyleChange.style_price;

            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "4") {
            this.shirtplacket = this.shirtstyleChange.image;
            this.shirtData.placketid = this.shirtstyleChange.id;
            this.cShirt.placketIDoption = this.shirtstyleChange.option_id;
            this.shirtData.placketname = this.shirtstyleChange.style_name;
            
            this.shirtData.placketprice = this.shirtstyleChange.style_price;

            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "5") {
            if (shirtstyle.id =="13") {
              this.shirtpocket = "";
              this.shirtData.pocketid = "";
              
              this.shirtData.pocketname = "";
              
              this.shirtData.pocketprice = "";
              this.hidepocket = true;
              this.hidefront = false;
            this.hideback = true;
            } else {
              this.shirtpocket = this.shirtstyleChange.image;
              this.shirtData.pocketid = this.shirtstyleChange.id;
              this.shirtData.pocketname = this.shirtstyleChange.pocket_name;
              
              this.shirtData.pocketprice = this.shirtstyleChange.pocket_price;

              this.hidepocket = false;
              this.hidefront = false;
            this.hideback = true;
            }
            
          }
          else if(this.userPostStyle.partid == "6") {
            this.shirtpocket = this.shirtstyleChange.image;
            this.shirtData.pocketid = this.shirtstyleChange.id;
            this.shirtData.pocketname = this.shirtstyleChange.pocket_name;
            
            this.shirtData.pocketprice = this.shirtstyleChange.pocket_price;

            this.hidepocket = false;
            this.hidefront = false;
            this.hideback = true;
          }
          else if(this.userPostStyle.partid == "7") {
            this.shirtpocket = this.shirtstyleChange.image;
            this.shirtData.pocketid = this.shirtstyleChange.id;
            this.shirtpocketflap = this.shirtstyleChange.flap;
            this.shirtData.pocketname = this.shirtstyleChange.pocket_name;
            
            this.shirtData.pocketprice = this.shirtstyleChange.pocket_price;

            console.log('here');
            console.log(this.shirtstyleChange.flap);
            console.log('End');
            this.hidepocket = false;
            this.hidefront = false;
           this.hideback = true;
          }
          else if(this.userPostStyle.partid == "8") {
            this.shirtback = this.shirtstyleChange.image;
            this.shirtData.backsideid = this.shirtstyleChange.id;
            this.shirtData.backsidename = this.shirtstyleChange.style_name;
            
            this.shirtData.backsideprice = this.shirtstyleChange.style_price;

            this.shirtbottom =null;
            this.hidefront = true;
           this.hideback = false;
          }
          else if(this.userPostStyle.partid == "9") {
            
            this.shirtbottom = this.shirtstyleChange.image;
            this.shirtData.btmcutid = this.shirtstyleChange.id;
            this.shirtData.btmcutname = this.shirtstyleChange.style_name;
            
            this.shirtData.btmprice = this.shirtstyleChange.style_price;

            this.hidebottom= false;
            this.hidefront = true;
           this.hideback = false;
          }
          
          else {
            console.log(this.shirtstyleChange);
          }
          
          
        } else {
          console.log("Error");
          this.hidebottom= true;
          this.shirtbottom=null;
          this.loading = false;
        }
      },
      err => {}
    );
  }
  
  // main navigations
  fabStage(){
    this.hideFabric = false;
    this.hideStyle = true;
    this.hideExtra = true;
    this.hideSize = true;
    this.hideSummery = true;
  }
  styleStage(){
    this.hideFabric = true;
    this.hideStyle = false;
    this.hideExtra = true;
    this.hideSize = true;
    this.hideSummery = true;
  }
  styleExtra(){
    this.hideFabric = true;
    this.hideStyle = true;
    this.hideExtra = false;
    this.hideSize = true;
    this.hideSummery = true;
  }
  sizeStage(){
    this.hideFabric = true;
    this.hideStyle = true;
    this.hideExtra = true;
    this.hideSize = false;
    this.hideSummery = true;
  }
  // hide and show images of sizes
  neck_bodys(){
    this.neck_body= false;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  chest_bodys(){
    this.neck_body= true;
    this.chest_body= false;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  waist_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = false;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  hips_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = false;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  shirtlength_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = false;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  shoulder_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = false;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  armlength_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = false;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  armhole_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = false;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  bicep_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = false;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  wrist_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = false;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  shortarm_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = false;
    this.cuffshort_body = true;
    this.shouldertypes_body = true;
    
  }
  shortcuff_bodys(){
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = false;
    this.shouldertypes_body = true;
    
  }
  neck_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = false;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  chest_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = false;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  waist_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = false;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  hips_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = false;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  shirtlength_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = false;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  shoulder_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = false;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  armlength_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = false;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  armhole_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = false;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  bicep_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = false;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  cuff_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = false;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }
  armshort_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = false;
    this.cuffshort_shirt = true;
    
  }
  cuffshort_shirts(){
    this.shouldertypes_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = false;
    
  }
  shouldertype_bodys(){
    this.shouldertypes_body = false;
    this.neck_body= true;
    this.chest_body= true;
    this.waist_body = true;
    this.hips_body = true;
    this.shirtlength_body = true;
    this.shoulder_body = true;
    this.armlength_body = true;
    this.armhole_body = true;
    this.bicep_body = true;
    this.wrist_body = true;
    this.armshort_body = true;
    this.cuffshort_body = true;
    this.neck_shirt = true;
    this.chest_shirt = true;
    this.waist_shirt = true;
    this.hips_shirt = true;
    this.shirtlength_shirt = true;
    this.shoulder_shirt = true;
    this.armlength_shirt = true;
    this.armhole_shirt = true;
    this.bicep_shirt = true;
    this.cuff_shirt = true;
    this.armshort_shirt = true;
    this.cuffshort_shirt = true;
    
  }

  summeryStage(){
    this.hideFabric = true;
    this.hideStyle = true;
    this.hideExtra = true;
    this.hideSize = true;
    this.hideSummery = false;
    if (this.shirtData.sizetype === "Standard Size") {
      console.log(this.stheight);
      console.log(this.stcollorsize);
      console.log(this.stfit);
      
      this.shirtData.typeoffitStandard = this.stfit;
      this.shirtData.colorsizes = this.stcollorsize;
      this.shirtData.heightStandard= this.stheight;
      this.shirtData.weightStandard= this.stweight;
      this.shirtData.shouderStandard= this.stshouldertype;
      this.shirtData.sleeveStandard= this.stsleeve;
      this.shirtData.chestStandard= this.stchest;
      this.shirtData.waistStandard= this.stwaist;
      this.shirtData.hipsStandard= this.sthip;
      this.shirtData.lenghtStandard= this.stlength;
      this.shirtData.sleevewidthStandard= this.stsleevew;
      this.shirtData.cuffwidthStandard= this.stcuffw;
      
    } 
    else if (this.shirtData.sizetype === "Measure Your Shirt") {
      
      this.shirtData.shirtshouldertype = this.shirtshouldertype ;
      this.shirtData.heightshirt = this.shirtheight ;
      this.shirtData.weightshirt = this.shirtweight ;
      this.shirtData.neckshirt = this.shirtneck ;
      this.shirtData.chestshirt = this.shirtchest ;
      this.shirtData.waistshirt = this.shirtwaist ;
      this.shirtData.hipsshirt = this.shirthips ;
      this.shirtData.shirtlenghtshirt = this.shirtlength ;
      this.shirtData.shouldershirt = this.shirtshoulder ;
      this.shirtData.armlenghtshirt = this.shirtarmlength ;
      this.shirtData.armholeshirt = this.shirtarmhole ;
      this.shirtData.bicepshirt = this.shirtbicep ;
      this.shirtData.cuffshirt = this.shirtcuffsize ;
      this.shirtData.armlengtshortshirt = this.shirtarmlengthshort ;
      this.shirtData.shortcuffshirt = this.shirtshortcuff ;

    }
    else if (this.shirtData.sizetype === "Measure Your Body") {
      
      this.shirtData.typeoffitbody = this.bodyfit ;
      this.shirtData.heightbody = this.bodyheight ;
      this.shirtData.weightbody = this.bodyweight ;
      this.shirtData.shouldertypebody = this.bodyshouldertype ;
      this.shirtData.neckbodys = this.bodyneck ;
      this.shirtData.chestbodys = this.bodychest ;
      this.shirtData.waistbodys = this.bodywaist ;
      this.shirtData.hipsbodys = this.bodyhip ;
      this.shirtData.shirtlenghtbodys = this.bodyshirtlength ;
      this.shirtData.shoulderbodys = this.bodyshoulder ;
      this.shirtData.armlenghtbodys = this.bodyarm ;
      this.shirtData.armholebodys = this.bodyarmhole ;
      this.shirtData.bicepbodys = this.bodybicep ;
      this.shirtData.wristbodys = this.bodywrist ;
      this.shirtData.armlengtshorthbodys = this.bodyarmlength ;
      this.shirtData.shortcuffbodys = this.bodycuffshort ;

    }
  }
  stndchange(i){
    this.stdc = i;

          this.shouderStandard1 = this.shouderStandard[this.stdc];
          this.sleeveStandard1 = this.sleeveStandard[this.stdc];
          this.chestStandard1 = this.chestStandard[this.stdc] ;
          this.waistStandard1 = this.waistStandard[this.stdc];
          this.hipsStandard1 = this.hipsStandard[this.stdc];
          this.lenghtStandard1 = this.lenghtStandard[this.stdc];
          this.sleevewidthStandard1 = this.sleevewidthStandard[this.stdc];
          this.cuffwidthStandard1 = this.cuffwidthStandard[this.stdc];

          this.shouldercount=i;
          this.sleevecount=i;
          this.chestcount=i;
          this.wasitcount=i;
          this.hipcount=i;
          this.lengthcount=i;
          this.sleevewidthcount=i;
          this.cuffwidth=i;

  }
  stndsizeclick(){
    this.hidesizes = true;
    this.hidestandard = false;
    this.shirtData.sizetype = "Standard Size";

  }
  stndsize(){
       this.loading = true;
    this.authService.postData(this.sleevetype, "standardsize").then(
      result => {
        this.responseStandardsize = result;
        if (this.responseStandardsize.Data) {
          this.loading = false;
          this.stdc=0;
          console.log(this.responseStandardsize.Data);
          this.standardsizes = this.responseStandardsize.Data;
          this.typeoffitStandard = this.standardsizes.fit_type;
          let a1 = this.standardsizes.collar.split(",");
          this.heightStandard = this.standardsizes.height;
          this.weightStandard = this.standardsizes.weight;
          let a2 = this.standardsizes.shoulder.split(",");
          let a3 = this.standardsizes.sleeves.split(",");
          let a4 = this.standardsizes.chest.split(",");
          let a5 = this.standardsizes.waist.split(",");
          let a6 = this.standardsizes.hip.split(",");
          let a7 = this.standardsizes.length.split(",");
          let a8 = this.standardsizes.sleeve_width.split(",");
          let a9 = this.standardsizes.cuff_length.split(",");

          this.colorsizes = a1;
          this.shouderStandard = a2[this.stdc];
          this.sleeveStandard = a3[this.stdc];
          this.chestStandard = a4[this.stdc];
          this.waistStandard = a5[this.stdc];
          this.hipsStandard = a6[this.stdc];
          this.lenghtStandard = a7[this.stdc];
          this.sleevewidthStandard = a8[this.stdc];
          this.cuffwidthStandard = a9[this.stdc];

          this.colorsizes1 = a1;
          this.shouderStandard1 = a2[this.stdc];
          this.sleeveStandard1 = a3[this.stdc];
          this.chestStandard1 = a4[this.stdc];
          this.waistStandard1 = a5[this.stdc];
          this.hipsStandard1 = a6[this.stdc];
          this.lenghtStandard1 = a7[this.stdc];
          this.sleevewidthStandard1 = a8[this.stdc];
          this.cuffwidthStandard1 = a9[this.stdc];

          this.shouldercount=this.stdc;
          this.sleevecount=this.stdc;
          this.chestcount=this.stdc;
          this.wasitcount=this.stdc;
          this.hipcount=this.stdc;
          this.lengthcount=this.stdc;
          this.sleevewidthcount=this.stdc;
          this.cuffwidth=this.stdc;
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );

  }

  stndchangeFit(Fit){
    this.hidesizes = true;
    this.hidestandard = false;
    this.fitType.ft = Fit;
    this.fitType.sl = this.sleevetype;
    this.loading = true;
    this.authService.postData(this.fitType, "standardsizechange").then(
      result => {
        this.responseStandardsize = result;
        if (this.responseStandardsize.Data) {
          this.loading = false;
          
          console.log(this.responseStandardsize.Data);
          this.standardsizes = this.responseStandardsize.Data;
          this.typeoffitStandard = this.standardsizes.fit_type;
          this.colorsizes = this.standardsizes.collar.split(",");
          this.heightStandard = this.standardsizes.height;
          this.weightStandard = this.standardsizes.weight;
          this.shouderStandard = this.standardsizes.shoulder.split(",");
          this.sleeveStandard = this.standardsizes.sleeves.split(",");
          this.chestStandard = this.standardsizes.chest.split(",");
          this.waistStandard = this.standardsizes.waist.split(",");
          this.hipsStandard = this.standardsizes.hip.split(",");
          this.lenghtStandard = this.standardsizes.length.split(",");
          this.sleevewidthStandard = this.standardsizes.sleeve_width.split(",");
          this.cuffwidthStandard = this.standardsizes.cuff_length.split(",");
          console.log(this.colorsizes);
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );

  }
  measurebody(){
    this.loading = true;
    this.authService.postData(this.sleevetype, "Bodysize").then(
      result => {
        this.responseBodysize = result;
        if (this.responseBodysize.Data) {
          this.loading = false;
          this.bodysizes=this.responseBodysize.Data;
          this.typeoffitbody= this.bodysizes.fit_type;
          this.heightbody= this.bodysizes.height;
          this.weightbody= this.bodysizes.weight;
          this.shouderbody= this.bodysizes.shoulder.split(",");
          this.neckbody= this.bodysizes.neck.split(",");
          this.chestbody= this.bodysizes.chest.split(",");
          this.waistbody= this.bodysizes.waist.split(",");
          this.hipsbody= this.bodysizes.hips.split(",");
          this.shirtlenghtbody= this.bodysizes.shirt_length.split(",");
          this.armlenghtbody= this.bodysizes.arm_length.split(",");
          this.armholebody= this.bodysizes.arm_hole.split(",");
          this.bicepbody= this.bodysizes.bicep.split(",");
          this.wristbody= this.bodysizes.wrist.split(",");
          this.armlengtshorthbody= this.bodysizes.short_arm_length.split(",");
          this.shortcuffbody= this.bodysizes.short_cuff.split(",");
          this.shirtData.sizetype="Measure Your Body";
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );

  }
  measureshirt(){
    this.loading = true;
    this.authService.postData(this.sleevetype, "shirtsize").then(
      result => {
        this.responseShirtsize = result;
        if (this.responseShirtsize.Data) {
          this.loading = false;
          this.shirtsizes=this.responseShirtsize.Data;
          console.log(this.shirtsizes);
          this.heightshirt= this.shirtsizes.height;
          this.weightshirt= this.shirtsizes.weight;
          this.shouldershirt= this.shirtsizes.shoulder.split(",");
          this.neckshirt= this.shirtsizes.neck.split(",");
          this.chestshirt= this.shirtsizes.chest.split(",");
          this.waistshirt= this.shirtsizes.waist.split(",");
          this.hipsshirt= this.shirtsizes.hips.split(",");
          this.shirtlenghtshirt= this.shirtsizes.shirt_length.split(",");
          this.armlenghtshirt= this.shirtsizes.arm_length.split(",");
          this.armholeshirt= this.shirtsizes.arm_hole.split(",");
          this.bicepshirt= this.shirtsizes.bicep.split(",");
          this.cuffshirt= this.shirtsizes.cuff.split(",");
          this.armlengtshortshirt= this.shirtsizes.short_arm_length.split(",");
          this.shortcuffshirt= this.shirtsizes.short_cuff.split(",");
          this.shirtData.sizetype="Measure Your Shirt";
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );

  }
 
  back(){
    this.hidesizes = false;
    this.hidestandard = true;
  }
  getitems() {
    this.loading = true;
    this.authService.postData(this.userPostData, "fabList").then(
      result => {
        this.responseData = result;
        if (this.responseData.Data) {
          this.loading = false;
          this.items = this.responseData.Data;
          
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
  }
  fun(item){
    
    this.userPostData.fabricid=item.id;
    this.cShirt.fabricID = item.id;
    
    console.log(this.cShirt);
    
    
    this.authService.postData(this.userPostData, "fabClick").then(
      result => {
        this.responseDataFabric = result;
        if (this.responseDataFabric.Data) {
          this.loading = false;
          this.fabData = this.responseDataFabric.Data;
          this.fabimg = this.fabData;
          
          console.log(this.fabimg );
          this.shirtcollar = this.fabimg[2].image;
          this.shirtcuff = this.fabimg[3].image;
          this.shirtbase = this.fabimg[0].image;
          this.shirtplacket = this.fabimg[4].image;
          this.shirtarms = this.fabimg[1].image;
          this.cShirt.fabricID = this.fabimg[2].fabric_id;
          this.cShirt.cuffID = this.fabimg[3].part_id;
          this.cShirt.cuffIDoption = this.fabimg[3].option_id;
          this.cShirt.collorID = this.fabimg[2].part_id;
          this.cShirt.collorIDoption = this.fabimg[2].option_id;
          this.cShirt.placketID = this.fabimg[4].part_id;
          this.cShirt.placketIDoption = this.fabimg[4].option_id;
          this.cShirt.sleeveID = this.fabimg[1].part_id;
          this.cShirt.baseID = this.fabimg[0].part_id;

          this.shirtData.cuffid = this.fabimg[3].styleid;
          this.shirtData.collarid = this.fabimg[2].styleid;
          this.shirtData.placketid = this.fabimg[4].styleid;
          this.shirtData.sleeveid = this.fabimg[1].styleid;
          this.shirtData.baseid = this.fabimg[0].styleid;
          this.shirtData.fabricid = this.fabimg[0].fabric_id;

          this.shirtData.cuffname = this.fabimg[3].style_name;
          this.shirtData.collarname = this.fabimg[2].style_name;
          this.shirtData.placketname = this.fabimg[4].style_name;
          this.shirtData.fabricname = this.fabimg[0].fabric_name;
          this.shirtData.fabricprice = this.fabimg[0].fabric_price;
          this.hidecuff = true;
          this.hidefront = false;
           this.hideback = true;
          this.fabricName = this.fabimg[0].fabric_name;
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
  }
  externalurl(id){
    
    this.userPostData.fabricid=id;
    this.cShirt.fabricID = id;
    
    console.log(this.cShirt);
    
    
    this.authService.postData(this.userPostData, "fabClick").then(
      result => {
        this.responseDataFabric = result;
        if (this.responseDataFabric.Data) {
          this.loading = false;
          this.fabData = this.responseDataFabric.Data;
          this.fabimg = this.fabData;
          
          console.log(this.fabimg );
          this.shirtcollar = this.fabimg[2].image;
          this.shirtcuff = this.fabimg[3].image;
          this.shirtbase = this.fabimg[0].image;
          this.shirtplacket = this.fabimg[4].image;
          this.shirtarms = this.fabimg[1].image;
          this.cShirt.fabricID = this.fabimg[2].fabric_id;
          this.cShirt.cuffID = this.fabimg[3].part_id;
          this.cShirt.cuffIDoption = this.fabimg[3].option_id;
          this.cShirt.collorID = this.fabimg[2].part_id;
          this.cShirt.collorIDoption = this.fabimg[2].option_id;
          this.cShirt.placketID = this.fabimg[4].part_id;
          this.cShirt.placketIDoption = this.fabimg[4].option_id;
          this.cShirt.sleeveID = this.fabimg[1].part_id;
          this.cShirt.baseID = this.fabimg[0].part_id;

          this.shirtData.cuffid = this.fabimg[3].styleid;
          this.shirtData.collarid = this.fabimg[2].styleid;
          this.shirtData.placketid = this.fabimg[4].styleid;
          this.shirtData.sleeveid = this.fabimg[1].styleid;
          this.shirtData.baseid = this.fabimg[0].styleid;
          this.shirtData.fabricid = this.fabimg[0].fabric_id;

          this.shirtData.cuffname = this.fabimg[3].style_name;
          this.shirtData.collarname = this.fabimg[2].style_name;
          this.shirtData.placketname = this.fabimg[4].style_name;
          this.shirtData.fabricname = this.fabimg[0].fabric_name;
          this.shirtData.fabricprice = this.fabimg[0].fabric_price;
          this.hidecuff = true;
          this.hidefront = false;
           this.hideback = true;
          this.fabricName = this.fabimg[0].fabric_name;
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
  }
  getParts() {
    this.loading = true;
    this.authService.postData(this.userPostData, "partsList").then(
      result => {
        this.responseDataparts = result;
        if (this.responseDataparts.Data) {
          this.loading = false;
          this.parts = this.responseDataparts.Data;
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
  }
  getRestParts() {
    this.loading = true;
    this.authService.postData(this.userPostData, "partsRestList").then(
      result => {
        this.responseDataparts = result;
        if (this.responseDataparts.Data) {
          this.loading = false;
          this.Extraparts = this.responseDataparts.Data;
        } else {
          console.log("Error");
          this.loading = false;
        }
      },
      err => {}
    );
  }

  addCart(){
    // let cart = JSON.parse(localStorage.getItem("cart"));
    //   console.log(cart);
    if (localStorage.getItem("cart") == null) {
      let cart: any = [];
      cart.push(JSON.stringify(this.shirtData));
      localStorage.setItem("cart", JSON.stringify(cart));
      
      // this.modalRef.hide();
    //this.router.navigate(["/cart"]);
    } else {
      let cart: any = JSON.parse(localStorage.getItem("cart"));
      cart.push(JSON.stringify(this.shirtData));
      localStorage.setItem("cart", JSON.stringify(cart));
      // this.modalRef.hide();
       //this.router.navigate(["/cart"]);
    }
    let cart = JSON.parse(localStorage.getItem("cart"));
    this.cartlength = cart.length;
  }
  shoulderPlus(){
    
    
    
    let b1 = this.standardsizes.shoulder.split(",");
    
    if (this.shouldercount < b1.length) {
      
      this.shouldercount = Number(this.shouldercount)+1;
      
      this.shouderStandard1 = b1[this.shouldercount];
      
    }
    
  }
  shoulderMinus(){
    
    let a2 = this.standardsizes.shoulder.split(",");
    
    if (this.shouldercount > 0) {
      
      this.shouldercount = Number(this.shouldercount)-1;
      
      this.shouderStandard1 = a2[this.shouldercount];
      
    }
    
  }
  sleevesPlus(){
    
    
    
    let a2 = this.standardsizes.sleeves.split(",");
    if (this.sleevecount < a2.length) {
      this.sleevecount = Number(this.sleevecount)+1;
      this.sleeveStandard1 = a2[this.sleevecount];
      
    }
    
  }
  sleevesMinus(){
    
    let a2 = this.standardsizes.sleeves.split(",");
    
    if (this.sleevecount > 0) {
      this.sleevecount = Number(this.sleevecount)-1;
      this.sleeveStandard1 = a2[this.sleevecount];
      
    }
    
  }
  chestPlus(){
    
    
    
    let a2 = this.standardsizes.chest.split(",");
    if (this.chestcount < a2.length) {
      this.chestcount = Number(this.chestcount)+1;
      this.chestStandard1 = a2[this.chestcount];
      
    }
    
  }
  chestMinus(){
    
    let a2 = this.standardsizes.chest.split(",");
    
    if (this.chestcount > 0) {
      this.chestcount = Number(this.chestcount)-1;
      this.chestStandard1 = a2[this.chestcount];
      
    }
    
  }
  waistPlus(){
    
    
    
    let a2 = this.standardsizes.waist.split(",");
    if (this.wasitcount < a2.length) {
      this.wasitcount = Number(this.wasitcount)+1;
      this.waistStandard1 = a2[this.wasitcount];
      
    }
    
  }
  waistMinus(){
    
    let a2 = this.standardsizes.waist.split(",");
    
    if (this.wasitcount > 0) {
      this.wasitcount = Number(this.wasitcount)-1;
      this.waistStandard1 = a2[this.wasitcount];
      
    }
    
  }
  hipPlus(){
    
    
    
    let a2 = this.standardsizes.hip.split(",");
    if (this.hipcount < a2.length) {
      this.hipcount = Number(this.hipcount)+1;
      this.hipsStandard1 = a2[this.hipcount];
      
    }
    
  }
  hipMinus(){
    
    let a2 = this.standardsizes.hip.split(",");
    
    if (this.hipcount > 0) {
      this.hipcount = Number(this.hipcount)-1;
      this.hipsStandard1 = a2[this.hipcount];
      
    }
    
  }
  lengthPlus(){
    
    
    
    let a2 = this.standardsizes.length.split(",");
    if (this.lengthcount < a2.length) {
      this.lengthcount = Number(this.lengthcount)+1;
      this.lenghtStandard1 = a2[this.lengthcount];
      
    }
    
  }
  lengthMinus(){
    
    let a2 = this.standardsizes.length.split(",");
    
    if (this.lengthcount > 0) {
      this.lengthcount = Number(this.lengthcount)-1;
      this.lenghtStandard1 = a2[this.lengthcount];
      
    }
    
  }
  sleevewidthPlus(){
    
    
    
    let a2 = this.standardsizes.sleeve_width.split(",");
    if (this.sleevewidthcount < a2.length) {
      this.sleevewidthcount = Number(this.sleevewidthcount)+1;
      this.sleevewidthStandard1 = a2[this.sleevewidthcount];
      
    }
    
  }
  sleevewidthMinus(){
    
    let a2 = this.standardsizes.sleeve_width.split(",");
    
    if (this.sleevewidthcount > 0) {
      this.sleevewidthcount = Number(this.sleevewidthcount)-1;
      this.sleevewidthStandard1 = a2[this.sleevewidthcount];
      
    }
    
  }
  cuffPlus(){
    
    
    
    let a2 = this.standardsizes.cuff_length.split(",");
    if (this.cuffwidth < a2.length) {
      this.cuffwidth = Number(this.cuffwidth)+1;
      this.cuffwidthStandard1 = a2[this.cuffwidth];
      
    }
    
  }
  cuffMinus(){
    
    let a2 = this.standardsizes.cuff_length.split(",");
    
    if (this.cuffwidth > 0) {
      this.cuffwidth = Number(this.cuffwidth)-1;
      this.cuffwidthStandard1 = a2[this.cuffwidth];
      
    }
    
  }
  
  CartPage(){
    
    this.router.navigate(["/cart"]);
    
  }
  openModal(template: TemplateRef<any>,item) {
    console.log(item);
    this.fabricinfo.fabric_name=item.fabric_name;
    this.fabricinfo.fabric_desc=item.description;
    this.fabricinfo.fabric_price=item.fabric_price;
    this.fabricinfo.fabric_tumbnail=item.thumbnail;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, {class: 'modal-lg' })
    );
  }
  openModal2(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, { class: 'modal-lg' });
  }
}
