import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
declare let TwoCoInlineCart: any ;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  items:any;
  total: any;
  userID:any;
  collorprice:any;
  cuffprice:any;
  totalqty:any;
  sleeveprice:any;
  pocketprice:any;
  btnprice:any;
  paymentConfirm:any;
  btmcutprice:any;
  backsideprice:any;
  insidecuff:any;
  emailscount:boolean;
  insidecollar:any;
  placketprice:any;
  shipCountry:any;
  cartinfo:any;
  logMail:any = null;
  loginmessage:any = "null";
  total_gr:any;
  lastID:any;
  grand_total:any;
  loggedOut:boolean;
  loggedIn:boolean;
  customerdat:any;
  shippingmode:boolean= true;
  paymentmode:boolean=true;
  countries:boolean;
  login={
    usermail:"",
    userpassword:""
  }
  createAccount={
    firstname:"",
    lastname:"",
    email:"",
    address:"",
    phonenumber:"",
    country:"",
    state:"",
    city:"",
    zipcode:"",
    passwords:""

  }
  
  customerinfo ={
    custId:"",
    firstname:"",
    lastname:"",
    email:"",
    address:"",
    address2:"",
    phonenumber:"",
    country:this.shipCountry,
    state:"",
    city:"",
    zipcode:"",
    totalamount:""
  }
  infos :any;
  responseaccount:any;
  responseshipments:any;
  responseRates:any;
  placeorder:any;
  shipRates:any = 0;
  logCheck = false;
  acCheck = true;
  constructor(private authService: AuthService, public router: Router) { }

  ngOnInit() {

    this.loadcart();
    this.shippcountries();
    let logged = JSON.parse(localStorage.getItem("logIn"));
    if(logged == undefined || logged =="" || logged == null){
      this.logCheck = false;
      this.acCheck = true;
      this.loggedOut = false;
      this.loggedIn = true;
      this.emailscount=true;
    }
    else{
      this.logCheck = true;
      this.acCheck = false;
      this.loggedOut = true;
      this.loggedIn = false;
      let userData = JSON.parse(logged[0]);
      this.logMail = userData.emailId;
      this.userID = userData.id;
      this.emailscount=false;
      }
      
  }
  loadcart() {
    this.total = 0;
    this.totalqty = 0;
    this.total_gr = 0;
    this.grand_total = 0;
    this.collorprice  = 0 ;
    this.cuffprice  = 0 ;
    this.sleeveprice  = 0 ;
    this.pocketprice  = 0 ;
    this.btnprice = 0 ;
    this.btmcutprice  = 0 ;
    this.backsideprice  = 0 ;
    this.insidecuff = 0 ;
    this.insidecollar = 0 ;
    this.placketprice = 0 ;
    this.items = [];

    if (
      localStorage.getItem("cart") == null ||
      localStorage.getItem("cart") == "[]"
    ) {
      
    } else {
      let cart = JSON.parse(localStorage.getItem("cart"));
      this.cartinfo = cart;
      for (var i = 0; i < cart.length; i++) {
        let item = JSON.parse(cart[i]);
        this.items.push({
          product: item
        });
        //this.total += item.totalP;
        this.totalqty += parseInt(item.qty);
        this.total += (item.fabricprice) * item.qty ;
        this.collorprice  += (item.collorprice) * item.qty ;
        this.cuffprice  += (item.cuffprice) * item.qty ;
        this.sleeveprice  += (item.sleeveprice) * item.qty ;
        this.pocketprice  += (item.pocketprice) * item.qty ;
        this.btnprice += (item.btnprice) * item.qty ;
        this.btmcutprice  += (item.btmprice) * item.qty ;
        this.backsideprice  += (item.backsideprice) * item.qty ;
        this.insidecuff += (item.insidecuffprice) * item.qty ;
        this.insidecollar += (item.collorinsdeprice) * item.qty ;
        this.placketprice += (item.placketprice) * item.qty ;
        this.total_gr = this.total + this.collorprice + this.cuffprice + this.sleeveprice + this.pocketprice + this.btnprice  + this.btmcutprice + this.backsideprice +this.insidecuff + this.insidecollar + this.placketprice;
        this.grand_total = this.total_gr;
      }
      
    }
  }
  createaccount(){
    
    this.authService.postData(this.createAccount, "createaccount").then(
      result => {
        this.responseaccount = result;
        console.log(this.responseaccount);
        if (this.responseaccount > 0) {
          location.reload();
          this.ngOnInit();
           
          
          
        } else {
          console.log("Error");
         
        }
      },
      err => {}
    );
  }
  shippcountries(){
    
    this.authService.postData(this.totalqty, "shippcountries").then(
      result => {
        this.responseshipments = result;
        
        if (this.responseshipments) {
          this.countries = this.responseshipments.Data;          
          
        } else {
          console.log("Error");
         
        }
      },
      err => {}
    );
  }
  shipprates(){
    
    this.authService.postData(this.shipCountry, "shipRates").then(
      result => {
        this.responseRates = result;
        
        if (this.responseRates) {
          console.log(this.responseRates);
                   
          if (this.totalqty === 1) {
            this.shipRates = this.responseRates.qty_1; 
          }
          else if(this.totalqty === 2){
            this.shipRates = this.responseRates.qty_2;
          }
          else if(this.totalqty === 3){
            this.shipRates = this.responseRates.qty_3;
          }
          else if(this.totalqty === 4){
            this.shipRates = this.responseRates.qty_4;
          }
          else if(this.totalqty === 5){
            this.shipRates = this.responseRates.qty_5;
          }
          else if(this.totalqty === 6){
            this.shipRates = this.responseRates.qty_6;
          }
          else if(this.totalqty === 7){
            this.shipRates = this.responseRates.qty_7;
          }
          else if(this.totalqty === 8){
            this.shipRates = this.responseRates.qty_8;
          }
          else if(this.totalqty === 9){
            this.shipRates = this.responseRates.qty_9;
          }
           else {
            this.shipRates = this.responseRates.qty_10;
          }
          this.grand_total = Number(this.total_gr) + Number(this.shipRates);
          this.customerinfo.totalamount = this.grand_total;
        } else {
          console.log("Error");
         
        }
      },
      err => {}
    );
  }
  coinline(){
   var condition = 'unapproved';
    (function(document, src, libName, config) {
   var script = document.createElement("script");
   script.src = src;
   script.async = true;
   var firstScriptElement = document.getElementsByTagName("script")[0];
   script.onload = function() {
     for (var namespace in config) {
       if (config.hasOwnProperty(namespace)) {
         window[libName].setup.setConfig(namespace, config[namespace]);
       }
     }
     window[libName].register();
     window[libName].cart.setTest(true);
   };
 
   firstScriptElement.parentNode.insertBefore(script, firstScriptElement);
   
 })(
   document,
   "https://secure.2checkout.com/checkout/client/twoCoInlineCart.js",
   "TwoCoInlineCart",
   {
     app: { merchant: "250195055426" },
     cart: { host: "https://secure.2checkout.com" }
   }
 );
 
//  var names = this.customerinfo.firstname + this.customerinfo.lastname;
//  var qty =  this.total_gr;
//  var price = this.total_gr;
   var self = this;
     
   TwoCoInlineCart.setup.setMode('DYNAMIC');
   TwoCoInlineCart.cart.setCurrency('USD');
   TwoCoInlineCart.billing.setEmail(self.logMail); 
   TwoCoInlineCart.products.removeAll();
   console.log(this.grand_total);
   var pricestoget = this.grand_total;
   TwoCoInlineCart.products.add({
     name: self.customerinfo.firstname + " " + this.customerinfo.lastname ,
     quantity: 1,
     price: pricestoget,
   });
   TwoCoInlineCart.events.subscribe('payment:finalized', function () {
      condition = 'completed';
      self.paymentConfirm = condition;
      self.placeCustomerInfo();
      
    });
   // TwoCoInlineCart.cart.setReturnMethod({
   //   type: 'redirect',
   //   url : 'http://my-test-site.com/return-url'
   // });
 
   
   TwoCoInlineCart.cart.setTest(true);
   // TwoCoInlineCart.cart.setSignature('{{$signature}}');
   TwoCoInlineCart.cart.checkout();
   
    this.paymentConfirm = condition;
    // this.testing12();
   
  }
   placeCustomerInfo(){
    // console.log(this.paymentConfirm);
    // console.log(this.customerinfo);
    if (this.paymentConfirm== 'completed') {
      
      this.authService.postData(this.customerinfo, "placeorder").then(
        result => {
          let lastid = result;
          if (lastid != null) {
           this.lastID = lastid;
            this.PlaceorderInfo();
          } else {
            console.log("Error");
          
          }
        }, 
        err => {}
      );
    }
   }
   PlaceorderInfo(){
    if (this.lastID > 0 ) {
      let combindinfo = []
      combindinfo.push(this.cartinfo,this.lastID);
      this.authService.postData(combindinfo, "placeorderinfo").then(
        result => {
          let lastid = result;
          if (lastid != null) {
           console.log(lastid);
           this.lastID = lastid;

          } else {
            console.log("Error");
          
          }
        },
        err => {}
      );
    }
   }
   paymentmodes(){
     this.paymentmode=false;
     this.shippingmode=true;
   }
  customerInfo(){
    
    this.authService.postData(this.userID, "customerinfo").then(
      result => {
        this.responseaccount = result;
        if (this.responseaccount) {
          this.infos = this.responseaccount.Data[0];
          console.log(this.infos.firstName);
          this.customerinfo.custId = this.infos.id ;
          this.customerinfo.firstname= this.infos.firstName;
          this.customerinfo.lastname= this.infos.lastName;
          this.customerinfo.email= this.infos.emailId;
          this.customerinfo.address= this.infos.address;
          this.customerinfo.phonenumber= this.infos.mobileNumber;
          this.customerinfo.country= this.infos.country;
          this.customerinfo.state= this.infos.state;
          this.customerinfo.city= this.infos.city;
          this.customerinfo.zipcode= this.infos.zipcode;
          this.shipCountry = this.infos.country;
          this.shipprates();
          this.customerdat = this.customerinfo;
          this.shippingmode= false;
          this.emailscount=true;
        } else {
          console.log("Error");
         
        }
      },
      err => {}
    );
  }
  profilego(){

    let datainfo = JSON.parse(localStorage.getItem("datainfo"));
    console.log(datainfo);
    let asd = JSON.parse(datainfo[0]);
    var mail = asd.usermail ;
    var pass = asd.userpassword ;
    var form = document.createElement("form");
    var element1 = document.createElement("input"); 
    var element2 = document.createElement("input");  

    form.method = "POST";
    form.action = "http://localhost/dashboard/user/login";
    form.target = "_blank";   

    element1.value= mail;
    element1.name="emailid";
    element1.type="hidden";
    form.appendChild(element1);  

    element2.value=pass;
    element2.name="password";
    element2.type="hidden";
    form.appendChild(element2);

    document.body.appendChild(form);

    form.submit();

    element1.value=" ";
    element1.name="emailid";
    element1.type="hidden";
    form.appendChild(element1);  

    element2.value=" ";
    element2.name="password";
    element2.type="hidden";
    form.appendChild(element2);

    document.body.appendChild(form);
  }
  signIn(){
    
    this.authService.postData(this.login, "login").then(
      result => {
        this.responseaccount = result;
        if (this.responseaccount.userData) {
          //console.log(this.responseaccount.userData);
          let logData = this.responseaccount.userData;
          let datas = this.login
          let token = logData.token;
          this.authService.token = token;
          let logIn: any = [];
          logIn.push(JSON.stringify(logData));
          localStorage.setItem("logIn", JSON.stringify(logIn));
          let logIns: any = [];
          logIns.push(JSON.stringify(datas));
          localStorage.setItem("datainfo", JSON.stringify(logIns));
          this.loggedOut = true;
          this.loggedIn = false;
          this.emailscount=false;
          this.ngOnInit();
        } else {
          console.log("Error");
          this.loginmessage = "Email Or Password is Wrong"
         
        }
      },
      err => {}
    );
  }
  returnMain(){
    this.router.navigate(["/home"]);
  }
}
